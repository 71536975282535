@charset "UTF-8";
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"); }

@font-face {
  font-family: Roboto-Bold;
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"); }

@font-face {
  font-family: Roboto-Medium;
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff"); }

@font-face {
  font-family: Scada-Bold;
  src: url("../fonts/Scada Bold.woff2") format("woff2"), url("../fonts/Scada-Bold.woff") format("woff"); }

*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #646464; }

body {
  display: flex;
  flex-direction: column;
  min-width: 320px; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 10px 0; }

h1 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 36px; }

h2 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 32px; }

h3 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 28px; }

h4 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 24px; }

h5 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 22px; }

h6 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px; }

p {
  font-size: 16px; }
  p strong {
    font-family: "Roboto-Bold", sans-serif; }

a {
  transition: color .5s;
  color: #388cd1; }
  a:hover {
    text-decoration: none;
    color: #388cd1; }

ol li,
ul li {
  padding: 3px 0; }

input,
textarea,
select,
button {
  border-radius: 0 !important;
  outline: none; }

input {
  padding: 3px 10px 5px 10px;
  color: #646464;
  border: 1px solid #646464; }
  input::placeholder {
    opacity: 1;
    /* Chrome/Opera/Safari */
    /* Firefox 19+ */
    /* IE 10+ */
    /* Firefox 18- */ }
    input::placeholder::-webkit-input-placeholder {
      opacity: 1;
      color: #646464; }
    input::placeholder::-moz-placeholder {
      opacity: 1;
      color: #646464; }
    input::placeholder:-ms-input-placeholder {
      opacity: 1;
      color: #646464; }
    input::placeholder:-moz-placeholder {
      opacity: 1;
      color: #646464; }

textarea {
  resize: none; }

input.error,
textarea.error {
  border-color: #ed1b24 !important;
  background: #fbd1d3 !important; }

svg {
  display: block;
  overflow: visible; }

img[alt=''],
img:not([alt]) {
  outline: 5px solid tomato; }

.fancybox-container img[alt=''],
.fancybox-container img:not([alt]),
.gm-style img[alt=''],
.gm-style img:not([alt]) {
  outline: none !important; }

ul > *:not(li),
ol > *:not(li) {
  outline: 5px solid tomato; }

.main-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch; }
  .main-wrap.no-scroll {
    overflow: hidden; }

.main {
  flex-grow: 1; }

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px; }
  @media screen and (max-width: 767px) {
    .container {
      padding: 0 20px; } }
  @media screen and (max-width: 425px) {
    .container {
      padding: 0 10px; } }

.title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 36px;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 10px;
  text-align: center;
  color: #3d3d3d; }
  @media screen and (max-width: 425px) {
    .title {
      font-size: 30px; } }
  .title::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 160px;
    height: 1px;
    margin: auto;
    content: '';
    background-color: #dbe6f1; }
  .title::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 3px;
    margin: auto;
    content: '';
    border-radius: 2px;
    background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%);
    box-shadow: 0 8px 26px rgba(115, 175, 228, 0.27); }

.burger {
  position: absolute;
  right: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 25;
  width: 31px;
  height: 31px;
  display: none; }
  @media screen and (max-width: 1090px) {
    .burger {
      display: block !important;
      margin: 7px 0; } }
  @media screen and (max-width: 767px) {
    .burger {
      right: 20px; } }
  @media screen and (max-width: 425px) {
    .burger {
      right: 10px; } }

.burger input {
  display: none; }

.burger label {
  position: relative;
  display: block;
  width: 31px;
  height: 31px;
  cursor: pointer;
  background: transparent; }

/* Exit Icon */
.burger label:before,
.burger input:checked + label:before {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  margin-top: -1px;
  content: '';
  transition: background-color .5s;
  border-radius: 2px;
  background-color: #fff; }

.burger label:before {
  animation: animationOneReverse 1s ease forwards; }

@keyframes animationOneReverse {
  0% {
    transform: rotate(315deg); }
  25% {
    transform: rotate(360deg); }
  50%,
  100% {
    transform: rotate(0deg); } }

.burger input:checked + label:before {
  animation: animationOne 1s ease forwards; }

@keyframes animationOne {
  0%,
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(315deg); } }

.burger label:after,
.burger input:checked + label:after {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  margin-top: -1px;
  content: '';
  transition: background-color .5s;
  border-radius: 2px;
  background-color: #fff; }

.burger label:after {
  animation: animationTwoReverse 1s ease forwards; }

@keyframes animationTwoReverse {
  0% {
    transform: rotate(405deg); }
  25% {
    transform: rotate(450deg); }
  50%,
  100% {
    transform: rotate(0deg); } }

.burger input:checked + label:after {
  animation: animationTwo 1s ease forwards; }

@keyframes animationTwo {
  0%,
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(450deg); }
  100% {
    transform: rotate(405deg); } }

/* Burger Icon */
.burger label .burger__icon:before {
  position: absolute;
  top: 7px;
  width: 30px;
  height: 2px;
  content: '';
  transition: background-color .5s;
  animation: animationBurgerTopReverse 1s ease forwards;
  border-radius: 2px;
  background-color: #fff; }

@keyframes animationBurgerTopReverse {
  0%,
  50% {
    transform: translateY(12px);
    opacity: 0; }
  51% {
    transform: translateY(12px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

.burger input:checked + label .burger__icon:before {
  animation: animationBurgerTop 1s ease forwards; }

@keyframes animationBurgerTop {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  50% {
    transform: translateY(12px);
    opacity: 1; }
  51%,
  100% {
    transform: translateY(12px);
    opacity: 0; } }

.burger label .burger__icon:after {
  position: absolute;
  bottom: 6px;
  width: 30px;
  height: 2px;
  content: '';
  transition: background-color .5s;
  animation: animationBurgerBottomReverse 1s ease forwards;
  border-radius: 2px;
  background-color: #fff; }

@keyframes animationBurgerBottomReverse {
  0%,
  50% {
    transform: translateY(-12px);
    opacity: 0; }
  51% {
    transform: translateY(-12px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

.burger input:checked + label .burger__icon:after {
  animation: animationBurgerBottom 1s ease forwards; }

@keyframes animationBurgerBottom {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  50% {
    transform: translateY(-12px);
    opacity: 1; }
  51%,
  100% {
    transform: translateY(-12px);
    opacity: 0; } }

.popup__layer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  opacity: .8;
  background-color: #2f2d2d; }

.popup,
.popup-success {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  display: none;
  width: 80%;
  max-width: 352px;
  max-height: 95vh;
  padding: 20px 40px 0 40px;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1); }

.popup__close {
  position: absolute;
  z-index: 1;
  top: -16px;
  right: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #388cd1; }
  .popup__close:hover svg {
    transform: rotate(360deg); }
  .popup__close svg {
    fill: #fff;
    width: 15px;
    height: 15px;
    transition: transform .5s;
    will-change: transform; }

.popup__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #3d3d3d; }

.popup__form {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .popup__form .form-order__input {
    width: 100%;
    height: 38px;
    margin: 15px 0 10px 0;
    border: 2px solid rgba(96, 153, 207, 0.2);
    border-radius: 19px;
    background-color: #fff; }
  .popup__form .form-order__btn {
    margin-top: 10px; }

.popup-success {
  padding-top: 70px; }
  .popup-success .popup__body {
    display: flex;
    justify-content: center; }
  .popup-success .form-order__btn {
    min-width: 190px;
    margin-top: 45px;
    margin-bottom: 40px;
    text-align: center; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/slick/slick-ajax-loader.gif") center center no-repeat; }

.slick-slider {
  user-select: auto; }
  .slick-slider * {
    outline: none; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  display: block;
  width: 48px;
  height: 48px;
  padding: 0;
  cursor: pointer;
  transition: .5s;
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  border-radius: 50% !important;
  outline: none;
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("../img/slick/slick-arr.png");
  background-repeat: no-repeat; }
  .slick-prev:hover,
  .slick-next:hover {
    background-color: #fff; }

.slick-prev {
  background-position: 12px 50%; }
  .slick-prev:hover {
    background-position: 12px 50%; }

.slick-next {
  background-position: -84px 50%; }
  .slick-next:hover {
    background-position: -84px 50%; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.form-order {
  display: flex;
  flex-wrap: wrap; }

.form-order__input {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  height: 38px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 0 35px;
  color: #5b5b5b;
  border: none;
  border-radius: 19px !important;
  background-color: #fff; }

.form-order__btn {
  position: relative;
  display: block;
  overflow: hidden;
  height: 38px;
  margin-bottom: 20px;
  padding: 0 25px;
  cursor: pointer;
  border: none;
  border-radius: 19px !important;
  background-color: transparent;
  box-shadow: 0 8px 26px rgba(115, 175, 228, 0.27); }
  .form-order__btn::before, .form-order__btn::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: auto;
    content: '';
    transition: opacity .5s; }
  .form-order__btn::before {
    opacity: 1;
    background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%); }
    .form-order__btn::before:hover {
      opacity: 0; }
  .form-order__btn::after {
    opacity: 0;
    background-image: linear-gradient(to top, #0071b8 0%, #2d85cf 100%); }
  .form-order__btn:hover::before {
    opacity: 0; }
  .form-order__btn:hover::after {
    opacity: 1; }
  .form-order__btn:hover span {
    color: #fff; }
  .form-order__btn span {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 38px;
    position: relative;
    z-index: 1;
    display: block;
    color: #fff; }
  .form-order__btn input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0; }

.footer {
  background-color: #f4f4f6; }
  .footer .container {
    padding-top: 15px; }

.footer__contacts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.footer__contact {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none; }
  .footer__contact:hover span {
    color: #5d8dcf; }
  .footer__contact svg {
    fill: #5d8dcf;
    width: 24px;
    height: 24px;
    margin-right: 15px; }
  .footer__contact span {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    transition: color .5s;
    white-space: nowrap;
    color: #3d3d3d; }

.footer__contact--phone span {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 18px; }

.footer__copyright {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
  padding: 10px 15px 15px 15px;
  text-align: center;
  color: #3d3d3d; }

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 6px; }
  @media screen and (max-width: 1090px) {
    .header .container {
      flex-wrap: wrap; } }
  @media screen and (max-width: 650px) {
    .header .container {
      flex-wrap: nowrap; } }

.header__logo {
  margin-right: 10px; }
  .header__logo img {
    display: block;
    width: 100%; }

.header__contacts {
  display: flex; }
  @media screen and (max-width: 1090px) {
    .header__contacts {
      justify-content: space-between;
      order: 5;
      width: 100%; } }
  @media screen and (max-width: 650px) {
    .header__contacts {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      overflow: hidden;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
      width: 0;
      height: 100vh;
      padding-top: 80px;
      transition: .5s;
      transform: translateX(160vw);
      background-color: #fff; }
      .header__contacts.active {
        width: 100%;
        transform: translateX(0); } }
  @media screen and (max-width: 767px) {
    .header__contacts .footer__contact {
      padding: 10px 5px; } }

.header__contacts-close {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  width: 15px;
  height: 15px;
  cursor: pointer; }
  @media screen and (max-width: 650px) {
    .header__contacts-close {
      display: block; } }
  .header__contacts-close svg {
    width: 15px;
    height: 15px; }

.header-btn {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 188px;
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: none;
  border-radius: 19px;
  background-image: linear-gradient(to top, #ff7953 0%, #fb8c62 100%);
  box-shadow: 0 4px 16px rgba(255, 125, 85, 0.27); }
  .header-btn::before, .header-btn::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: auto;
    content: '';
    transition: opacity .5s; }
  .header-btn::before {
    opacity: 1;
    background-image: linear-gradient(to top, #ff7953 0%, #fb8c62 100%); }
    .header-btn::before:hover {
      opacity: 0; }
  .header-btn::after {
    opacity: 0;
    background-image: linear-gradient(to top, #fa6236 0%, #fb7847 100%); }
  .header-btn:hover::before {
    opacity: 0; }
  .header-btn:hover::after {
    opacity: 1; }
  .header-btn:hover span {
    color: #fff; }
  .header-btn svg {
    position: relative;
    z-index: 1;
    flex: none;
    fill: #fff;
    width: 19px;
    height: 21px;
    margin-right: 17px; }
  .header-btn span {
    font-family: "Scada-Bold", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 38px;
    position: relative;
    z-index: 1;
    display: inline-block;
    white-space: nowrap;
    color: #fff; }

.info-btn {
  display: none;
  margin-left: 10px; }
  @media screen and (max-width: 650px) {
    .info-btn {
      display: block; } }
  .info-btn svg {
    fill: #5d8dcf;
    width: 38px;
    height: 38px; }

.menu-section {
  position: relative;
  z-index: 1;
  background-color: #388cd1; }
  .menu-section .container {
    display: flex; }
    @media screen and (max-width: 1090px) {
      .menu-section .container {
        min-height: 44px; } }

.menu {
  width: 100%;
  background-color: #388cd1; }
  @media screen and (max-width: 1090px) {
    .menu {
      display: none; } }

.menu__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 1090px) {
    .menu__list {
      flex-direction: column;
      padding-bottom: 50px; } }
  @media screen and (max-width: 550px) {
    .menu__list {
      align-items: flex-start; } }
  @media screen and (max-width: 375px) {
    .menu__list {
      margin-top: 30px; } }

.menu__item {
  position: relative;
  padding: 18px 10px 18px 10px;
  list-style: none; }
  @media screen and (max-width: 1090px) {
    .menu__item {
      width: 100%;
      max-width: 320px; } }
  .menu__item::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    margin: auto;
    content: '';
    transition: width .5s;
    background-color: #fd8960; }
  .menu__item.active::before, .menu__item:hover::before {
    width: 100%; }
  .menu__item.active .menu__link, .menu__item:hover .menu__link {
    color: #c5def8; }
  .menu__item.active .menu__item-svg, .menu__item:hover .menu__item-svg {
    transform: rotate(180deg); }
    .menu__item.active .menu__item-svg svg, .menu__item:hover .menu__item-svg svg {
      fill: #fd8960; }
  .menu__item:hover .menu__sublist {
    pointer-events: auto;
    opacity: 1; }

.menu__item--parent {
  padding-right: 25px; }

.menu__item-svg {
  position: absolute;
  top: 24px;
  right: 5px;
  width: 10px;
  height: 10px;
  transition: transform .5s; }
  .menu__item-svg svg {
    fill: #fff;
    width: 10px;
    height: 10px; }

.menu__link {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 15px;
  line-height: 19px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff; }

.menu__sublist {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  max-width: 180px;
  margin: auto;
  padding: 15px 28px 15px 24px;
  transition: opacity .5s;
  pointer-events: none;
  opacity: 0;
  background-color: #0071b8; }
  @media screen and (max-width: 1090px) {
    .menu__sublist {
      position: relative;
      display: none;
      margin: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      pointer-events: auto;
      opacity: 1;
      background-color: transparent; } }

.menu__subitem {
  padding: 0;
  list-style: none; }

.menu__sublink {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 32px;
  text-decoration: none;
  color: #fff; }
  .menu__sublink:hover {
    color: #ff855c; }

.top {
  background-image: url("../img/bg-top.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 767px) {
    .top {
      background-position: 60% 50%; } }
  .top .container {
    min-height: 420px;
    padding-top: 60px;
    padding-bottom: 60px; }

.top__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  color: #3d3d3d; }
  @media screen and (max-width: 767px) {
    .top__title {
      font-size: 35px;
      line-height: 1.2; } }

.top__subtitle {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 24px;
  margin-bottom: 50px;
  color: #3d3d3d; }
  @media screen and (max-width: 767px) {
    .top__subtitle {
      font-size: 20px; } }

.about .container {
  max-width: 1060px;
  padding-top: 45px;
  padding-bottom: 25px; }
  .about .container .title {
    margin-bottom: 30px; }
  .about .container p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: inline-block;
    margin: 0 0 22px 0;
    color: #3d3d3d; }

.options-list {
  background-color: #f3f3f3;
  background-image: url("../img/bg-options.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  .options-list .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 35px;
    padding-bottom: 10px; }
    @media screen and (max-width: 1020px) {
      .options-list .container {
        justify-content: space-around; } }

.options__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33% - 30px);
  max-width: 317px;
  margin: 0 15px 23px 15px; }
  @media screen and (max-width: 1090px) {
    .options__item {
      width: calc(33.33% - 10px);
      margin: 0 5px 23px 5px; } }
  @media screen and (max-width: 1020px) {
    .options__item {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 650px) {
    .options__item {
      width: 100%; } }

.options__pic {
  position: relative;
  overflow: hidden;
  width: calc(100% - 27px);
  max-width: 290px;
  height: 240px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  .options__pic:hover a {
    opacity: 1; }
  .options__pic:hover + .options__info {
    background-color: #fff; }
    .options__pic:hover + .options__info .options__title {
      color: #2f85cf; }
  .options__pic a {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transition: opacity .5s;
    text-align: center;
    text-decoration: none;
    opacity: 0;
    color: #fff;
    background-color: rgba(55, 139, 210, 0.35); }

.options__info {
  max-width: 317px;
  margin-top: -130px;
  padding: 155px 15px 12px 15px;
  transition: background-color .5s;
  border-radius: 24px;
  background-color: transparent;
  box-shadow: 0 8px 26px rgba(115, 175, 228, 0.09); }

.options__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
  margin-bottom: 13px;
  padding-bottom: 14px;
  transition: color .5s;
  color: #3d3d3d; }
  .options__title::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 76px;
    height: 1px;
    content: '';
    border-radius: 1px;
    background-image: linear-gradient(to top, #71aee4 0%, #8ebbe9 100%);
    box-shadow: 0 8px 26px rgba(115, 175, 228, 0.27); }

.options__text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 20px;
  color: #3d3d3d; }

.options__footer {
  display: flex;
  flex-wrap: wrap; }

.options__btn {
  font-family: "Roboto", sans-serif;
  position: relative;
  display: inline-block;
  overflow: hidden;
  flex: none;
  height: 38px;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 0 25px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  border-radius: 19px !important;
  background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%);
  box-shadow: 0 8px 26px rgba(115, 175, 228, 0.27); }
  .options__btn::before, .options__btn::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: auto;
    content: '';
    transition: opacity .5s; }
  .options__btn::before {
    opacity: 1;
    background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%); }
    .options__btn::before:hover {
      opacity: 0; }
  .options__btn::after {
    opacity: 0;
    background-image: linear-gradient(to top, #0071b8 0%, #2d85cf 100%); }
  .options__btn:hover::before {
    opacity: 0; }
  .options__btn:hover::after {
    opacity: 1; }
  .options__btn:hover span {
    color: #fff; }
  .options__btn span {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 38px;
    position: relative;
    z-index: 1;
    display: block;
    color: #fff; }

.options__price {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; }

.options__price-sm {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
  color: #3d3d3d; }

.options__price-lg {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 24px;
  line-height: 18px;
  display: inline-block;
  color: #3d3d3d; }

.guaranty .container {
  max-width: 1060px;
  padding-top: 18px;
  padding-bottom: 25px; }
  .guaranty .container .title {
    margin-bottom: 30px; }
  .guaranty .container p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: inline-block;
    margin: 0 0 22px 0;
    color: #3d3d3d; }

.how-it-work__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 60px;
  padding-bottom: 30px; }

.how-it-work__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  max-width: 250px;
  margin-bottom: 45px;
  padding: 0 10px; }
  @media screen and (max-width: 1020px) {
    .how-it-work__item {
      width: 50%;
      max-width: 100%; } }
  .how-it-work__item:hover .how-it-work__icon svg {
    opacity: 1; }
  .how-it-work__item:last-child::before {
    display: none; }
  .how-it-work__item::before {
    position: absolute;
    top: 30px;
    right: -30px;
    width: 32px;
    height: 22px;
    content: '';
    background-image: url("../img/right-arrow.png"); }
    @media screen and (max-width: 1020px) {
      .how-it-work__item::before {
        display: none; } }

.how-it-work__top {
  position: relative;
  margin-bottom: 30px; }

.how-it-work__numb {
  font-family: "Scada-Bold", sans-serif;
  font-size: 112px;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: -10px;
  display: block;
  color: #2f85cf; }

.how-it-work__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: rgba(219, 230, 241, 0.5); }
  .how-it-work__icon::before {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 106px;
    height: 106px;
    content: '';
    border-radius: 50%;
    background-color: transparent;
    box-shadow: 0 0 0 1px #dbe6f1; }
  .how-it-work__icon svg {
    fill: #2f85cf;
    width: 52px;
    height: 50px;
    transition: opacity .5s;
    opacity: 0; }

.how-it-work__text {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #3d3d3d; }

.order {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 1020px) {
    .order {
      background-position: 40% 50%; } }
  @media screen and (max-width: 650px) {
    .order {
      background-position: 100% 50%; } }
  .order .container {
    display: flex;
    justify-content: flex-end; }
  .order .form-order__btn::before {
    background-image: linear-gradient(to top, #ff7953 0%, #fb8c62 100%); }
  .order .form-order__btn::after {
    background-image: linear-gradient(to top, #fa6236 0%, #fb7847 100%); }

.order--first {
  background-image: url("../img/bg-order-01.jpg"); }
  .order--first .order__title,
  .order--first .order__subtitle {
    color: #fff; }

.order--second {
  background-image: url("../img/bg-order-02.jpg"); }
  .order--second .order__title,
  .order--second .order__subtitle {
    color: #3d3d3d; }

.order__item {
  width: 100%;
  max-width: 550px;
  padding: 60px 0; }
  @media screen and (max-width: 1090px) {
    .order__item {
      max-width: 500px; } }
  @media screen and (max-width: 1020px) {
    .order__item {
      max-width: 300px; } }
  @media screen and (max-width: 650px) {
    .order__item {
      max-width: 100%; } }

.order__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 48px;
  line-height: 60px;
  max-width: 400px;
  margin-bottom: 0; }
  @media screen and (max-width: 1020px) {
    .order__title {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 15px; } }

.order__subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 40px; }
  @media screen and (max-width: 1020px) {
    .order__subtitle {
      font-size: 20px; } }

.we-use .container {
  padding-top: 38px;
  padding-bottom: 20px; }

.we-use .title {
  margin-bottom: 45px; }

.we-use__row {
  display: flex;
  padding-bottom: 25px; }
  @media screen and (max-width: 1150px) {
    .we-use__row {
      justify-content: center;
      flex-wrap: wrap; } }

.we-use__item {
  width: 100%;
  margin-bottom: 20px;
  padding-top: 27px; }
  .we-use__item span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    margin-bottom: 15px;
    color: #3d3d3d; }
    .we-use__item span:last-child {
      margin-bottom: 0; }

.we-use__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  position: relative;
  margin-bottom: 22px;
  padding-bottom: 15px;
  color: #3d3d3d; }
  .we-use__title::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 160px;
    height: 1px;
    content: '';
    background-color: #dbe6f1; }

.we-use__card {
  display: flex;
  flex: none;
  width: 255px;
  min-height: 331px;
  margin-bottom: 20px;
  margin-left: 34px;
  cursor: pointer;
  border-radius: 24px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: 0 8px 26px rgba(115, 175, 228, 0.23); }
  @media screen and (max-width: 1150px) {
    .we-use__card {
      flex: inherit;
      width: calc(33.33% - 23px);
      max-width: 255px;
      margin-right: 34px;
      margin-left: 0; }
      .we-use__card:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 650px) {
    .we-use__card {
      width: calc(50% - 23px);
      margin-right: 15px;
      margin-left: 15px; } }
  @media screen and (max-width: 550px) {
    .we-use__card {
      width: 100%;
      margin-right: 0;
      margin-left: 0; } }
  @media screen and (max-width: 375px) {
    .we-use__card {
      max-width: 100%; } }
  .we-use__card:hover .we-use__text {
    opacity: 1; }

.we-use__text {
  width: 100%;
  min-height: 331px;
  padding: 45px 30px;
  transition: opacity .5s;
  opacity: 0;
  border-radius: 24px;
  background-color: #438fd0; }
  .we-use__text span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    margin-bottom: 20px;
    color: #fff; }
    .we-use__text span:last-child {
      margin-bottom: 0; }

.advantages {
  padding-top: 15px; }

.advantages__list {
  background-image: url("../img/bg-advantages.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  .advantages__list .container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 53px; }

.advantages__item {
  display: flex;
  width: 50%;
  margin: 0 0 60px 0;
  padding-left: 65px; }
  @media screen and (max-width: 767px) {
    .advantages__item {
      width: 100%;
      margin: 0 0 30px 0; } }
  @media screen and (max-width: 1020px) {
    .advantages__item {
      padding-left: 15px; } }

.advantages__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  width: 94px;
  height: 94px;
  margin-top: 5px;
  margin-right: 35px;
  border-radius: 50%;
  background-color: rgba(219, 230, 241, 0.5); }
  @media screen and (max-width: 375px) {
    .advantages__icon {
      margin-right: 15px; } }
  .advantages__icon img {
    display: block;
    max-width: 52px;
    max-height: 50px; }
  .advantages__icon::before {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 106px;
    height: 106px;
    content: '';
    border-radius: 50%;
    background-color: transparent;
    box-shadow: 0 0 0 1px #dbe6f1; }

.advantages__info {
  max-width: 270px; }
  @media screen and (max-width: 767px) {
    .advantages__info {
      max-width: 100%; } }
  .advantages__info span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #3d3d3d; }

.advantages__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 18px;
  color: #3d3d3d; }

.advantages-2 {
  padding-top: 15px;
  padding-bottom: 45px; }
  .advantages-2 .title {
    margin-bottom: 50px; }
  .advantages-2 .container {
    max-width: 1125px; }

.advantages-2__item {
  display: flex;
  margin-bottom: 35px; }
  @media screen and (max-width: 860px) {
    .advantages-2__item {
      flex-direction: column; } }

.advantages-2__pic {
  flex: none;
  width: 380px;
  margin-right: 60px; }
  @media screen and (max-width: 550px) {
    .advantages-2__pic {
      margin-right: 0; } }
  @media screen and (max-width: 425px) {
    .advantages-2__pic {
      width: 100%; } }
  .advantages-2__pic img {
    display: block;
    width: 100%; }

.advantages-2__info {
  padding-top: 23px; }
  .advantages-2__info span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    margin-bottom: 15px;
    color: #3d3d3d; }

.advantages-2__title {
  font-family: "Scada-Bold", sans-serif;
  font-size: 30px;
  line-height: 36px;
  position: relative;
  margin-bottom: 13px;
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #358bd1; }
  .advantages-2__title::before {
    position: absolute;
    bottom: 0;
    left: -40px;
    width: 160px;
    height: 1px;
    content: '';
    background-color: #dbe6f1; }
    @media screen and (max-width: 860px) {
      .advantages-2__title::before {
        left: 0; } }

.gallery {
  padding-top: 25px;
  padding-bottom: 30px; }
  .gallery .title {
    margin-bottom: 45px; }

.gallery__slider {
  width: calc(100% + 30px);
  margin-left: -15px; }
  @media screen and (max-width: 425px) {
    .gallery__slider {
      width: calc(100% + 20px);
      margin-left: -10px; } }
  .gallery__slider .slick-prev {
    left: 30px; }
  .gallery__slider .slick-next {
    right: 30px; }

.gallery__slide {
  padding: 0 15px; }
  .gallery__slide img {
    display: block;
    width: 100%; }

.partners {
  padding-top: 50px; }
  .partners .title {
    margin-bottom: 28px; }

.partners__slider {
  width: calc(100% - 150px);
  margin: 0 auto 20px; }
  @media screen and (max-width: 768px) {
    .partners__slider {
      width: calc(100% - 15px); } }
  @media screen and (max-width: 425px) {
    .partners__slider {
      width: 100%; } }
  .partners__slider .slick-prev {
    left: -70px;
    background-color: transparent; }
    @media screen and (max-width: 768px) {
      .partners__slider .slick-prev {
        left: -10px; } }
    @media screen and (max-width: 425px) {
      .partners__slider .slick-prev {
        left: 5px; } }
    .partners__slider .slick-prev:hover {
      background-color: transparent; }
  .partners__slider .slick-next {
    right: -70px;
    background-color: transparent; }
    @media screen and (max-width: 768px) {
      .partners__slider .slick-next {
        right: -10px; } }
    @media screen and (max-width: 425px) {
      .partners__slider .slick-next {
        right: 5px; } }
    .partners__slider .slick-next:hover {
      background-color: transparent; }

.partners__slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 110px;
  padding: 10px; }
  .partners__slide img {
    display: block;
    max-width: 100%; }
