.top {
  background-image: url('../img/bg-top.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include max(767) {
    background-position: 60% 50%;
  }
  .container {
    min-height: 420px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.top__title {
  font-family: $font-title;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  color: #3d3d3d;

  @include max(767) {
    font-size: 35px;
    line-height: 1.2;
  }
}

.top__subtitle {
  font-family: $font-medium;
  font-size: 24px;
  margin-bottom: 50px;
  color: #3d3d3d;

  @include max(767) {
    font-size: 20px;
  }
}
