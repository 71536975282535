.options-list {
  background-color: #f3f3f3;
  background-image: url('../img/bg-options.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 35px;
    padding-bottom: 10px;

    @include max(1020) {
      justify-content: space-around;
    }
  }
}

.options__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33% - 30px);
  max-width: 317px;
  margin: 0 15px 23px 15px;

  @include max(1090) {
    width: calc(33.33% - 10px);
    margin: 0 5px 23px 5px;
  }
  @include max(1020) {
    width: calc(50% - 10px);
  }
  @include max(650) {
    width: 100%;
  }
}

.options__pic {
  position: relative;
  overflow: hidden;
  width: calc(100% - 27px);
  max-width: 290px;
  height: 240px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  &:hover {
    a {
      opacity: 1;
    }
    & + .options__info {
      background-color: #fff;
      .options__title {
        color: #2f85cf;
      }
    }
  }
  a {
    font-family: $font;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transition: opacity .5s;
    text-align: center;
    text-decoration: none;
    opacity: 0;
    color: #fff;
    background-color: rgba(55, 139, 210, .35);
  }
}

.options__info {
  max-width: 317px;
  margin-top: -130px;
  padding: 155px 15px 12px 15px;
  transition: background-color .5s;
  border-radius: 24px;
  background-color: transparent;
  box-shadow: 0 8px 26px rgba(115, 175, 228, .09);
}

.options__title {
  font-family: $font-title;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
  margin-bottom: 13px;
  padding-bottom: 14px;
  transition: color .5s;
  color: #3d3d3d;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 76px;
    height: 1px;
    content: '';
    border-radius: 1px;
    background-image: linear-gradient(to top, #71aee4 0%, #8ebbe9 100%);
    box-shadow: 0 8px 26px rgba(115, 175, 228, .27);
  }
}

.options__text {
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 20px;
  color: #3d3d3d;
}

.options__footer {
  display: flex;
  flex-wrap: wrap;
}

.options__btn {
  font-family: $font;
  position: relative;
  display: inline-block;
  overflow: hidden;
  flex: none;
  height: 38px;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 0 25px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  border-radius: 19px !important;
  background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%);
  box-shadow: 0 8px 26px rgba(115, 175, 228, .27);

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: auto;
    content: '';
    transition: opacity .5s;
  }
  &::before {
    opacity: 1;
    background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%);
    &:hover {
      opacity: 0;
    }
  }
  &::after {
    opacity: 0;
    background-image: linear-gradient(to top, #0071b8 0%, #2d85cf 100%);
  }
  &:hover {
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
    span {
      color: #fff;
    }
  }
  span {
    font-family: $font;
    font-size: 17px;
    font-weight: 500;
    line-height: 38px;
    position: relative;
    z-index: 1;
    display: block;
    color: #fff;
  }
}

.options__price {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.options__price-sm {
  font-family: $font;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
  color: #3d3d3d;
}

.options__price-lg {
  font-family: $font-bold;
  font-size: 24px;
  line-height: 18px;
  display: inline-block;
  color: #3d3d3d;
}
