.how-it-work__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 60px;
  padding-bottom: 30px;
}

.how-it-work__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  max-width: 250px;
  margin-bottom: 45px;
  padding: 0 10px;

  @include max(1020) {
    width: 50%;
    max-width: 100%;
  }
  &:hover {
    .how-it-work__icon svg {
      opacity: 1;
    }
  }
  &:last-child {
    &::before {
      display: none;
    }
  }
  &::before {
    position: absolute;
    top: 30px;
    right: -30px;
    width: 32px;
    height: 22px;
    content: '';
    background-image: url('../img/right-arrow.png');

    @include max(1020) {
      display: none;
    }
  }
}

.how-it-work__top {
  position: relative;
  margin-bottom: 30px;
}

.how-it-work__numb {
  font-family: $font-title;
  font-size: 112px;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: -10px;
  display: block;
  color: #2f85cf;
}

.how-it-work__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: rgba(219, 230, 241, .5);
  &::before {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 106px;
    height: 106px;
    content: '';
    border-radius: 50%;
    background-color: transparent;
    box-shadow: 0 0 0 1px rgba(219, 230, 241, 1);
  }
  svg {
    fill: #2f85cf;
    width: 52px;
    height: 50px;
    transition: opacity .5s;
    opacity: 0;
  }
}

.how-it-work__text {
  font-family: $font;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #3d3d3d;
}
