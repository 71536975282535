.popup__layer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  opacity: .8;
  background-color: #2f2d2d;
}

.popup,
.popup-success {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  display: none;
  width: 80%;
  max-width: 352px;
  max-height: 95vh;
  padding: 20px 40px 0 40px;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, .1);
}

.popup__close {
  position: absolute;
  z-index: 1;
  top: -16px;
  right: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #388cd1;
  &:hover {
    svg {
      transform: rotate(360deg);
    }
  }
  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
    transition: transform .5s;

    will-change: transform;
  }
}

.popup__title {
  font-family: $font-title;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #3d3d3d;
}

.popup__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .form-order__input {
    width: 100%;
    height: 38px;
    margin: 15px 0 10px 0;
    border: 2px solid rgba(96, 153, 207, .2);
    border-radius: 19px;
    background-color: #fff;
  }
  .form-order__btn {
    margin-top: 10px;
  }
}

.popup-success {
  padding-top: 70px;
  .popup__body {
    display: flex;
    justify-content: center;
  }
  .form-order__btn {
    min-width: 190px;
    margin-top: 45px;
    margin-bottom: 40px;
    text-align: center;
  }
}
