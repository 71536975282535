*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-family: $font;
  font-size: 16px;
  line-height: 1.38;
  color: $text;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 10px 0;
}

h1 {
  font-family: $font-bold;
  font-size: 36px;
}

h2 {
  font-family: $font-bold;
  font-size: 32px;
}

h3 {
  font-family: $font-bold;
  font-size: 28px;
}

h4 {
  font-family: $font-bold;
  font-size: 24px;
}

h5 {
  font-family: $font-bold;
  font-size: 22px;
}

h6 {
  font-family: $font-bold;
  font-size: 20px;
}

p {
  font-size: 16px;
  strong {
    font-family: $font-bold;
  }
}

a {
  transition: color .5s;
  color: $highlight;
  &:hover {
    text-decoration: none;
    color: $highlight-hov;
  }
}

ol,
ul {
  li {
    padding: 3px 0;
  }
}

ul {
  li {
    // путь к кастомной картинке пункта ul
    // list-style-image: url('../img/ul.png');
  }
}

//кастомные пункты списка с цифрами
ol {
  // list-style: none;
  // @include counter(li);
  li::before {
    // color: $brand;
  }
}

input,
textarea,
select,
button {
  border-radius: 0 !important;
  outline: none;
}

input {
  padding: 3px 10px 5px 10px;
  color: $text;
  border: 1px solid $text;
  &::placeholder {
    @include placeholder-color($text);
  }
}

textarea {
  resize: none;
}

input.error,
textarea.error {
  border-color: #ed1b24 !important;
  background: #fbd1d3 !important;
}

svg {
  display: block;
  overflow: visible;
}

// подсвечиваем картинки без alt и без заполненного alt
img[alt=''],
img:not([alt]) {
  outline: 5px solid tomato;
}

// исключение для google-map и fancybox
.fancybox-container img[alt=''],
.fancybox-container img:not([alt]),
.gm-style img[alt=''],
.gm-style img:not([alt]) {
  outline: none !important;
}

// тревога, если первый child внутри списка не li и прочие похожие примеры
ul > *:not(li),
ol > *:not(li) {
  outline: 5px solid tomato;
}

//изменяем цвет выделения текста мышью
::selection {
  // background-color: $brand;
  // color: $brand;
  // text-shadow: none;
}

.main-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  //свойство для плавной прокрутки на яблоках

  -webkit-overflow-scrolling: touch;

  &.no-scroll {
    overflow: hidden;
  }
}

.main {
  flex-grow: 1;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  @include max(767) {
    padding: 0 20px;
  }
  @include max(425) {
    padding: 0 10px;
  }
}

.title {
  font-family: $font-title;
  font-size: 36px;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 10px;
  text-align: center;
  color: #3d3d3d;

  @include max(425) {
    font-size: 30px;
  }
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 160px;
    height: 1px;
    margin: auto;
    content: '';
    background-color: #dbe6f1;
  }
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 3px;
    margin: auto;
    content: '';
    border-radius: 2px;
    background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%);
    box-shadow: 0 8px 26px rgba(115, 175, 228, .27);
  }
}
