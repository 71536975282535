/* Slider */
.slick-loading .slick-list {
  background: #fff url('../img/slick/slick-ajax-loader.gif') center center no-repeat;
}

.slick-slider {
  user-select: auto;
  * {
    outline: none;
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  display: block;
  width: 48px;
  height: 48px;
  padding: 0;
  cursor: pointer;
  transition: .5s;
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  border-radius: 50% !important;
  outline: none;
  background-color: rgba(255, 255, 255, .8);
  background-image: url('../img/slick/slick-arr.png');
  background-repeat: no-repeat;
  &:hover {
    background-color: #fff;
  }
}

.slick-prev {
  background-position: 12px 50%;
  &:hover {
    background-position: 12px 50%;
  }
}

.slick-next {
  background-position: -84px 50%;
  &:hover {
    background-position: -84px 50%;
  }
}

.slick-prev {
  left: -25px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-next {
  right: -25px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}
