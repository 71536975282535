@font-face {
  font-family: Roboto;
  src:  url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff')  format('woff');
}

@font-face {
  font-family: Roboto-Bold;
  src:  url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff')  format('woff');
}

@font-face {
  font-family: Roboto-Medium;
  src:  url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff')  format('woff');
}

@font-face {
  font-family: Scada-Bold;
  src:  url('../fonts/Scada Bold.woff2') format('woff2'),
        url('../fonts/Scada-Bold.woff')  format('woff');
}
