.gallery {
  padding-top: 25px;
  padding-bottom: 30px;
  .title {
    margin-bottom: 45px;
  }
}

.gallery__slider {
  width: calc(100% + 30px);
  margin-left: -15px;

  @include max(425) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .slick-prev {
    left: 30px;
  }
  .slick-next {
    right: 30px;
  }
}

.gallery__slide {
  padding: 0 15px;
  img {
    display: block;
    width: 100%;
  }
}
