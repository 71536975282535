.about {
  .container {
    max-width: 1060px;
    padding-top: 45px;
    padding-bottom: 25px;

    .title {
      margin-bottom: 30px;
    }

    p {
      font-family: $font;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      display: inline-block;
      margin: 0 0 22px 0;
      color: #3d3d3d;
    }
  }
}
