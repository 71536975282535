.form-order {
  display: flex;
  flex-wrap: wrap;
}

.form-order__input {
  font-family: $font;
  font-size: 18px;
  font-weight: 400;
  height: 38px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 0 35px;
  color: #5b5b5b;
  border: none;
  border-radius: 19px !important;
  background-color: #fff;
}

.form-order__btn {
  position: relative;
  display: block;
  overflow: hidden;
  height: 38px;
  margin-bottom: 20px;
  padding: 0 25px;
  cursor: pointer;
  border: none;
  border-radius: 19px !important;
  background-color: transparent;
  box-shadow: 0 8px 26px rgba(115, 175, 228, .27);
  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: auto;
    content: '';
    transition: opacity .5s;
  }
  &::before {
    opacity: 1;
    background-image: linear-gradient(to top, #3d90d3 0%, #2d85cf 100%);
    &:hover {
      opacity: 0;
    }
  }
  &::after {
    opacity: 0;
    background-image: linear-gradient(to top, #0071b8 0%, #2d85cf 100%);
  }
  &:hover {
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
    span {
      color: #fff;
    }
  }
  span {
    font-family: $font;
    font-size: 17px;
    font-weight: 500;
    line-height: 38px;
    position: relative;
    z-index: 1;
    display: block;
    color: #fff;
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
  }
}
