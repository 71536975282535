.burger {
  position: absolute;
  right: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 25;
  width: 31px;
  height: 31px;
  display: none;
  @include max(1090) {
    display: block !important;
    margin: 7px 0;
  }
  @include max(767) {
    right: 20px;
  }
  @include max(425) {
    right: 10px;
  }
}

.burger input {
  display: none;
}

.burger label {
  position: relative;
  display: block;
  width: 31px;
  height: 31px;
  cursor: pointer;
  background: transparent;
}

/* Exit Icon */
.burger label:before,
.burger input:checked + label:before {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  margin-top: -1px;
  content: '';
  transition: background-color .5s;
  border-radius: 2px;
  background-color: #fff;
}

.burger label:before {
  animation: animationOneReverse 1s ease forwards;
}

@keyframes animationOneReverse {
  0% {
    transform: rotate(315deg);
  }
  25% {
    transform: rotate(360deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

.burger input:checked + label:before {
  animation: animationOne 1s ease forwards;
}

@keyframes animationOne {
  0%,
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(315deg);
  }
}

.burger label:after,
.burger input:checked + label:after {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  margin-top: -1px;
  content: '';
  transition: background-color .5s;
  border-radius: 2px;
  background-color: #fff;
}

.burger label:after {
  animation: animationTwoReverse 1s ease forwards;
}

@keyframes animationTwoReverse {
  0% {
    transform: rotate(405deg);
  }
  25% {
    transform: rotate(450deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

.burger input:checked + label:after {
  animation: animationTwo 1s ease forwards;
}

@keyframes animationTwo {
  0%,
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(450deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

/* Burger Icon */
.burger label .burger__icon:before {
  position: absolute;
  top: 7px;
  width: 30px;
  height: 2px;
  content: '';
  transition: background-color .5s;
  animation: animationBurgerTopReverse 1s ease forwards;
  border-radius: 2px;
  background-color: #fff;
}

@keyframes animationBurgerTopReverse {
  0%,
  50% {
    transform: translateY(12px);
    opacity: 0;
  }
  51% {
    transform: translateY(12px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.burger input:checked + label .burger__icon:before {
  animation: animationBurgerTop 1s ease forwards;
}

@keyframes animationBurgerTop {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(12px);
    opacity: 1;
  }
  51%,
  100% {
    transform: translateY(12px);
    opacity: 0;
  }
}

.burger label .burger__icon:after {
  position: absolute;
  bottom: 6px;
  width: 30px;
  height: 2px;
  content: '';
  transition: background-color .5s;
  animation: animationBurgerBottomReverse 1s ease forwards;
  border-radius: 2px;
  background-color: #fff;
}

@keyframes animationBurgerBottomReverse {
  0%,
  50% {
    transform: translateY(-12px);
    opacity: 0;
  }
  51% {
    transform: translateY(-12px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.burger input:checked + label .burger__icon:after {
  animation: animationBurgerBottom 1s ease forwards;
}

@keyframes animationBurgerBottom {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-12px);
    opacity: 1;
  }
  51%,
  100% {
    transform: translateY(-12px);
    opacity: 0;
  }
}
