.advantages-2 {
  padding-top: 15px;
  padding-bottom: 45px;
  .title {
    margin-bottom: 50px;
  }
  .container {
    max-width: 1125px;
  }
}

.advantages-2__item {
  display: flex;
  margin-bottom: 35px;

  @include max(860) {
    flex-direction: column;
  }
}

.advantages-2__pic {
  flex: none;
  width: 380px;
  margin-right: 60px;

  @include max(550) {
    margin-right: 0;
  }
  @include max(425) {
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
  }
}

.advantages-2__info {
  padding-top: 23px;
  span {
    font-family: $font;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    margin-bottom: 15px;
    color: #3d3d3d;
  }
}

.advantages-2__title {
  font-family: $font-title;
  font-size: 30px;
  line-height: 36px;
  position: relative;
  margin-bottom: 13px;
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #358bd1;
  &::before {
    position: absolute;
    bottom: 0;
    left: -40px;
    width: 160px;
    height: 1px;
    content: '';
    background-color: #dbe6f1;

    @include max(860) {
      left: 0;
    }
  }
}

//
//.advantages__list {
//  background-image: url('../img/bg-advantages.jpg');
//  background-repeat: no-repeat;
//  background-position: 50% 50%;
//  background-size: cover;
//  .container {
//    display: flex;
//    flex-wrap: wrap;
//    padding-top: 53px;
//  }
//}
//
//.advantages__item {
//  display: flex;
//  width: 50%;
//  margin: 0 0 60px 0;
//  padding-left: 65px;
//
//  @include max(767) {
//    width: 100%;
//    margin: 0 0 30px 0;
//  }
//  @include max(1020) {
//    padding-left: 15px;
//  }
//}
//
//.advantages__icon {
//  position: relative;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  flex: none;
//  width: 94px;
//  height: 94px;
//  margin-top: 5px;
//  margin-right: 35px;
//  border-radius: 50%;
//  background-color: rgba(219, 230, 241, .5);
//
//  @include max(375) {
//    margin-right: 15px;
//  }
//  img {
//    display: block;
//    max-width: 52px;
//    max-height: 50px;
//  }
//  &::before {
//    position: absolute;
//    top: -6px;
//    left: -6px;
//    width: 106px;
//    height: 106px;
//    content: '';
//    border-radius: 50%;
//    background-color: transparent;
//    box-shadow: 0 0 0 1px rgba(219, 230, 241, 1);
//  }
//}
//
//.advantages__info {
//  max-width: 270px;
//
//  @include max(767) {
//    max-width: 100%;
//  }
//  span {
//    font-family: $font;
//    font-size: 12px;
//    font-weight: 400;
//    line-height: 16px;
//    color: #3d3d3d;
//  }
//}
//
//.advantages__title {
//  font-family: $font-title;
//  font-size: 18px;
//  color: #3d3d3d;
//}
