.menu-section {
  position: relative;
  z-index: 1;
  background-color: #388cd1;
  .container {
    display: flex;

    @include max(1090) {
      min-height: 44px;
    }
  }
}

.menu {
  width: 100%;
  background-color: #388cd1;

  @include max(1090) {
    display: none;
  }
}

.menu__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;

  @include max(1090) {
    flex-direction: column;
    padding-bottom: 50px;
  }
  @include max(550) {
    align-items: flex-start;
  }
  @include max(375) {
    margin-top: 30px;
  }
}

.menu__item {
  position: relative;
  padding: 18px 10px 18px 10px;
  list-style: none;

  @include max(1090) {
    width: 100%;
    max-width: 320px;
  }
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    margin: auto;
    content: '';
    transition: width .5s;
    background-color: #fd8960;
  }
  &.active,
  &:hover {
    &::before {
      width: 100%;
    }
    .menu__link {
      color: #c5def8;
    }
    .menu__item-svg {
      transform: rotate(180deg);
      svg {
        fill: #fd8960;
      }
    }
  }
  &:hover {
    .menu__sublist {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.menu__item--parent {
  padding-right: 25px;
}

.menu__item-svg {
  position: absolute;
  top: 24px;
  right: 5px;
  width: 10px;
  height: 10px;
  transition: transform .5s;
  svg {
    fill: #fff;
    width: 10px;
    height: 10px;
  }
}

.menu__link {
  font-family: $font-bold;
  font-size: 15px;
  line-height: 19px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
}

.menu__sublist {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  max-width: 180px;
  margin: auto;
  padding: 15px 28px 15px 24px;
  transition: opacity .5s;
  pointer-events: none;
  opacity: 0;
  background-color: #0071b8;

  @include max(1090) {
    position: relative;
    display: none;
    margin: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    pointer-events: auto;
    opacity: 1;
    background-color: transparent;
  }
}

.menu__subitem {
  padding: 0;
  list-style: none;
}

.menu__sublink {
  font-family: $font;
  font-size: 15px;
  line-height: 32px;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #ff855c;
  }
}
