.footer {
  background-color: #f4f4f6;
  .container {
    padding-top: 15px;
  }
}

.footer__contacts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer__contact {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  &:hover {
    span {
      color: #5d8dcf;
    }
  }
  svg {
    fill: #5d8dcf;
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
  span {
    font-family: $font;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    transition: color .5s;
    white-space: nowrap;
    color: #3d3d3d;
  }
}

.footer__contact--phone {
  span {
    font-family: $font-medium;
    font-size: 18px;
  }
}

.footer__copyright {
  font-family: $font;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
  padding: 10px 15px 15px 15px;
  text-align: center;
  color: #3d3d3d;
}
