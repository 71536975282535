.advantages {
  padding-top: 15px;
}

.advantages__list {
  background-image: url('../img/bg-advantages.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  .container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 53px;
  }
}

.advantages__item {
  display: flex;
  width: 50%;
  margin: 0 0 60px 0;
  padding-left: 65px;

  @include max(767) {
    width: 100%;
    margin: 0 0 30px 0;
  }
  @include max(1020) {
    padding-left: 15px;
  }
}

.advantages__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  width: 94px;
  height: 94px;
  margin-top: 5px;
  margin-right: 35px;
  border-radius: 50%;
  background-color: rgba(219, 230, 241, .5);

  @include max(375) {
    margin-right: 15px;
  }
  img {
    display: block;
    max-width: 52px;
    max-height: 50px;
  }
  &::before {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 106px;
    height: 106px;
    content: '';
    border-radius: 50%;
    background-color: transparent;
    box-shadow: 0 0 0 1px rgba(219, 230, 241, 1);
  }
}

.advantages__info {
  max-width: 270px;

  @include max(767) {
    max-width: 100%;
  }
  span {
    font-family: $font;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #3d3d3d;
  }
}

.advantages__title {
  font-family: $font-title;
  font-size: 18px;
  color: #3d3d3d;
}
